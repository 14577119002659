import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Message from 'components/content/Message';
export const _frontmatter = {
  "path": "/developer/think-react",
  "date": "2016-01-03",
  "title": "THINK REACT",
  "author": "admin",
  "tags": ["development", "javascript", "react.js"],
  "featuredImage": "feature.jpg",
  "excerpt": "As we know, React is a library for creating user interfaces. It renders your UI and responds to events aka: the V in MVC. Because it is not as opinionated as many of other front-end JavaScript libraries, it plays nicely with your stack, whatever it maybe, Ruby, Java, Node.js or .NET."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`Why React?`}</h2>
    <p>{`React combines DOM generation and display logic. It is designed to re-render the whole app on every update via `}<inlineCode parentName="p">{`state`}</inlineCode>{`, and its implementation consists of `}<inlineCode parentName="p">{`Virtual DOM`}</inlineCode>{` and `}<inlineCode parentName="p">{`synthetic events`}</inlineCode>{`. These best practices make React a powerful tool for developing your web applications.`}</p>
    <h2>{`Build Components, Not Templates`}</h2>
    <p>{`Templates encourage a poor separation of concerns, as templates separate technologies, not concerns.`}</p>
    <Message type="info" title="Separation of Concerns" content="Reduce `coupling`, increase `cohesion`." mdxType="Message" />
    <p>{`What is coupling and what is cohesion?`}</p>
    <h3>{`Coupling`}</h3>
    <p>{`The degree to which each program module relies on each of the other modules.`}</p>
    <h3>{`Cohesion`}</h3>
    <p>{`The degree to which elements of a module belong together.`}</p>
    <p>{`Templates encourage a poor separation of concerns. Angular style directives are a good example of this. The `}<inlineCode parentName="p">{`View Model`}</inlineCode>{` tightly couples template to display logic. This is mentioned in Angular docs:`}</p>
    <p>{`However isolated scope creates a new problem: if a transcluded DOM is a child of the widget isolated scope then it will not be able to bind to anything. For this reason the transcluded scope is a child of the original scope, before the widget created an isolated scope for its local variables. This makes the transcluded and widget isolated scope siblings.`}</p>
    <p>{`The framework cannot know how to separate your concerns for you. It should only provide powerful `}<inlineCode parentName="p">{`expressive tools`}</inlineCode>{` for the user to do it correctly. Enter `}<inlineCode parentName="p">{`React component`}</inlineCode>{`.`}</p>
    <Message type="info" title="React Component" content="A `highly cohesive` building block for UIs `loosely coupled` with other components." mdxType="Message" />
    <h2>{`Why Components?`}</h2>
    <p>{`Instead of templates, we can use components to separate your concerns. In React these components are written in JavaScript, which is highly powerful.`}</p>
    <p>{`Components are:`}</p>
    <ul>
      <li parentName="ul">{`Reusable`}</li>
      <li parentName="ul">{`Composable`}</li>
      <li parentName="ul">{`Unit testable`}</li>
    </ul>
    <p>{`Make components small and only put display logic in your components.`}</p>
    <h2>{`JSX`}</h2>
    <p>{`JSX is an optional preprocessor to let you use HTML-like syntax. With JSX, it's easy for designers to contribute code.`}</p>
    <h2>{`State`}</h2>
    <p>{`Re-rendering the whole app on every update makes React stand out from other front-end libraries/frameworks.`}</p>
    <p>{`Data changing over time can make it really difficult for the front-end developer. When data changes, React re-renders the entire component.So the React components describe your UI at any point in time, just like a server-rendered app. This means every place data is displayed is guaranteed to be up-to date.`}</p>
    <p>{`State means:`}</p>
    <ul>
      <li parentName="ul">{`No magical data binding.`}</li>
      <li parentName="ul">{`No more explicit DOM operations - everything is declarative.`}</li>
      <li parentName="ul">{`No model dirty checking.`}</li>
    </ul>
    <h2>{`Virtual DOM`}</h2>
    <p>{`You can’t just throw out the DOM and rebuild it on each update. It’s too slow and you’ll lose form state and scroll position. So Facebook built a `}<inlineCode parentName="p">{`Virtual DOM`}</inlineCode>{` and events system.`}</p>
    <p>{`It is optimized for performance and memory footprint. Let's look how React use virtual DOM.`}</p>
    <p>{`On every update to the component, React:`}</p>
    <ul>
      <li parentName="ul">{`Builds a new virtual DOM subtree`}</li>
      <li parentName="ul">{`Diffs it with the old one`}</li>
      <li parentName="ul">{`Computes the minimal set of DOM mutations and puts them in a queue`}</li>
      <li parentName="ul">{`And batch executes all updates`}</li>
    </ul>
    <Message type="info" title="Virtual DOM is fast" content="Because it computes minimal DOM operations and batches reads and writes for optimal DOM performance it is usually faster than manual DOM operations at 60fps. It also implements automatic top-level event delegation (with cross-browser HTML5 events)." mdxType="Message" />
    <h2>{`React & Node.js`}</h2>
    <p>{`React can run in Node.js (new in 0.4) with optimizations based on app structure`}</p>
    <h2>{`Conclusion`}</h2>
    <p>{`React also has SVG, VML and `}<inlineCode parentName="p">{`<canvas>`}</inlineCode>{` support, and can run the whole app in a Web Worker(experimental). So think React, think components not templates, re-render, don't mutate.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      